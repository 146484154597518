<template>
  <v-card  class="pa-2"
        outlined
        tile>
       <h2 class="text-center "> Конструктор бизнес-ланча </h2> 
       <h3  >По будням с 12:00 - 16:00 </h3> 
       
          <v-stepper v-model="e1">
                <v-stepper-header>
                        <v-stepper-step
                                :complete="e1 > 1"
                                step="1"
                        >
                        Выберите набор
                        </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Выберите позиции из меню
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        Завершите заказ
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">                
        <v-row  dense
        >
        <v-col class="d-flex justify-center mb-6">
        <a @click="openCalc(1), e1 = 2" >
        <v-card min-width="230" max-width="300px" >
            <v-card-title>
                Комплекс 1  <v-spacer></v-spacer> 
                <div class="green--text text--lighten-1">{{CALCS[0] ? CALCS[0].price : ""}} руб.</div>
            </v-card-title>
             <v-list-item-avatar size="40">
                            <v-img src="../../assets/salad.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/soup.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar  size="40">
                            <v-img src="../../assets/tea.svg">
                            </v-img>
                    </v-list-item-avatar>
        </v-card>
        </a>
        </v-col>

        <v-col class="d-flex justify-center mb-6">
        <a @click="openCalc(2), e1 = 2">
        <v-card min-width="230" max-width="300px">
            <v-card-title>
                Комплекс 2 <v-spacer></v-spacer> 
                <div class="green--text text--lighten-1"> {{CALCS[1] ? CALCS[1].price : ""}} руб.</div>
            </v-card-title>
             <v-list-item-avatar size="40">
                            <v-img src="../../assets/salad.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/garnir.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/skewer.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/tea.svg">
                            </v-img>
                    </v-list-item-avatar>
        </v-card>
        </a>
        </v-col>
        </v-row>

        <v-row>
        <v-col class="d-flex justify-center mb-6">
        <a @click="openCalc(3), e1 = 2"> 
        <v-card min-width="230" max-width="300px">
            <v-card-title>
                Комплекс 3   <v-spacer></v-spacer> 
                <div class="green--text text--lighten-1">{{CALCS[2] ? CALCS[2].price : ""}} руб.</div>
            </v-card-title>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/soup.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/garnir.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/skewer.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/tea.svg">
                            </v-img>
                    </v-list-item-avatar>
        </v-card>
        </a>
        </v-col>

        <v-col  class="d-flex justify-center mb-6">
        <a @click="openCalc(4), e1 = 2">
        <v-card min-width="230" max-width="300px" >
            <v-card-title>
                Комплекс 4  
                <v-spacer></v-spacer> 
                <div class="green--text text--lighten-1">{{CALCS[3] ? CALCS[3].price : ""}} руб.</div>
                
            </v-card-title>
             <v-list-item-avatar size="40">
                            <v-img src="../../assets/salad.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/soup.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/garnir.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/skewer.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/tea.svg">
                            </v-img>
                    </v-list-item-avatar>
        </v-card>
        </a>
        </v-col>
        </v-row>

      </v-stepper-content>

      <v-stepper-content step="2">

                <lunch1 v-if="lunch1 == true" @nextPage="nextPage()" @backPage="backPage" @addFormData="getFormData" :salads_data="salads" :soups_data="soups"   :garnir_data="[]" :skewer_data="[]" :drink_data="drink" />
                <lunch1 v-if="lunch2 == true" @nextPage="nextPage()" @backPage="backPage" @addFormData="getFormData" :salads_data="salads" :soups_data="[]"   :garnir_data="garnir" :skewer_data="skewer" :drink_data="drink" />
                <lunch1 v-if="lunch3 == true" @nextPage="nextPage()" @backPage="backPage" @addFormData="getFormData" :salads_data="[]" :soups_data="soups"   :garnir_data="garnir" :skewer_data="skewer" :drink_data="drink" />
                <lunch1 v-if="lunch4 == true" @nextPage="nextPage()" @backPage="backPage" @addFormData="getFormData" :salads_data="salads" :soups_data="soups"   :garnir_data="garnir" :skewer_data="skewer" :drink_data="drink" />

      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card
          class="mb-12"
          
        >
        <v-card-text class="black--text"><h2>Обед готов</h2></v-card-text>
      
        <v-simple-table>
                <template v-slot:default>
                <thead>
                        <tr>
                        <th>
                        Тип
                        </th>
                        <th >
                        Цена
                        </th>
                         <th>
                        Состав обеда
                        </th>
                        </tr>
                </thead>
                <tbody>
                        <tr>
                                <td>{{formData.name}}</td>
                                <td>{{formData.price}} руб.</td>
                                <td>{{formData.description}}</td>
                        </tr>
                        <v-btn
                                class="ml-5"
                                color="deep-orange darken-1"
                                @click="addToCart(formData)"
                                >
                                        В корзину
                        </v-btn>
                        
                </tbody>
                </template>
        </v-simple-table>  
        
        </v-card>

        <v-btn
        color="primary"
        @click="backPage(2)"
        >
                Назад
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>

  </v-card>
  
</template>
<script>
import lunch1 from "../lunchcalc/components/lunch1";
import {mapGetters, mapActions} from 'vuex';

export default {

        components: {
                lunch1,
        },
        data(){
                return{
                        formData: {
                                id: 0,
                                name: '',
                                description: '',
                                price: 0,
                                isCalc: true,
                        },
                        e1: 1,
                        lunchType: 0,
                        lunch1: false,
                        lunch2: false,
                        lunch3: false,
                        lunch4: false,
                        
                        salads: [
                                {
                                        id: 1,
                                        name: 'Салат Летний',
                                        description: 'огурец, помидор, перец болгарский, лук красный, зелень, масло растительное, соевый соус (150 г)'
                                },
                                {
                                        id: 2,
                                        name: 'Салат Тбилиский',
                                        description: 'говядина отварная, перец болгарский, лук красный, фасоль консервированная, масло растительное, кинза, грецкий орех (120 г)'
                                },
                                {
                                        id: 3,
                                        name: 'Салат Азиатский',
                                        description: 'говядина отварная, помидор, шампиньоны жареные, огурец, масло растительное, соевый соус (120 г)'
                                },
                                {
                                        id: 4,
                                        name: 'Салат с адыгейским сыром и томатами',
                                        description: 'помидоры, сыр адыгейский, лист салата, заправка на основе растительного и оливкового масла, базилик, кинза, сок лимона (150 г)'
                                }
                        ],
                        soups: [
                                {
                                        id: 5,
                                        name: 'Суп Чечевичный',
                                        description: 'лук, морковь, чечевица красная, нут, картофель, лимон (250 г)'
                                },
                                {
                                        id: 6,
                                        name: 'Уха по-Царски',
                                        description: 'лук, морковь, картофель, семга, зелень (250 г)'
                                },
                                {
                                        id: 7,
                                        name: 'Солянка по-грузински',
                                        description: 'говядина, лук, морковь, помидор, огурцы соленые, зелень (250 г)'
                                },
                                {
                                        id: 8,
                                        name: 'Шурпа',
                                        description: 'лук, морковь, картофель, перец болгарский, баранина, зелень (300 г)'
                                }
                        ],
                        garnir: [
                                {
                                        id: 9,
                                        name: 'Булгур с грибами (200 г)'
                                },
                                {
                                        id: 10,
                                        name: 'Картофель жареный с луком (200 г)'
                                },
                                {
                                        id: 11,
                                        name: 'Картофель по-деревенски (150 г)'
                                },
                                {
                                        id: 12,
                                        name: 'Гречка томленая с ветчиной (200 г)'
                                }
                        ],
                        skewer: [
                                {
                                        id: 13,
                                        name: 'Кебаб из телятины',
                                        description: 'фарш из говядины, лук, зелень, соус томатный (110 г)'
                                },
                                {
                                        id: 14,
                                        name: 'Кебаб из баранины',
                                        description: 'фарш из баранины, лук, зелень, соус томатный (110 г)'
                                },
                                {
                                        id: 15,
                                        name: 'Скумбрия на мангале',
                                        description: 'скумбрия, лист салата, оливки/маслины, подается с чесночным соусом (120 г)'
                                },
                                {
                                        id: 16,
                                        name: 'Шашлык из индейки',
                                        description: ' (120 г)'
                                },
                                {
                                        id: 17,
                                        name: 'Шашлык свинины',
                                        description: ' (110 г)'
                                }
                        ],
                        drink: [
                                {
                                        id: 18,
                                        name: 'Морс клюква (250 мл)'
                                },
                                {
                                        id: 19,
                                        name: 'Морс смородина (250 мл)'
                                },
                                {
                                        id: 20,
                                        name: 'Морс облепиха (250 мл)'
                                },
                                {
                                        id: 21,
                                        name: 'Компот яблоко-вишня (250 мл)'
                                },
                                {
                                        id: 22,
                                        name: 'Компот абрикос (250 мл)'
                                },
                                {
                                        id: 23,
                                        name: 'Чай пакетированный (черный/зеленый) (250 мл)'
                                }
                        ]
                }
        },
        computed: {
                ...mapGetters([
                        'CART',
                        'CALCRESULT',
                        'CALCS'
                ]),      

        },
        methods:{
                ...mapActions([
                        'ADD_TO_CART',
                        'ADD_TO_CALC_RESULT',
                        'GET_LUNCHCALCS_FROM_API',
                ]),

                addToCart(data){
                        ++data.id;
                        this.ADD_TO_CART(data);
                        this.backPage(1);
                },
                openCalc(lunchType){
                        switch(lunchType)
                        {
                                case 1:
                                        this.closeOtherLunch();
                                        this.formData.id    = 301;
                                        this.formData.name  = 'Комплекс 1';
                                        this.formData.price = this.CALCS[0].price;
                                        this.lunch1 = true;
                                        break;
                                case 2:
                                        this.closeOtherLunch();
                                        this.formData.id    = 302;
                                        this.formData.name  = 'Комплекс 2';
                                        this.formData.price = this.CALCS[1].price;
                                        this.lunch2 = true;
                                        break;
                                case 3: 
                                        this.closeOtherLunch();
                                        this.formData.id    = 303;
                                        this.formData.name  = 'Комплекс 3';
                                        this.formData.price = this.CALCS[2].price;
                                        this.lunch3 = true;
                                        break;
                                case 4:
                                        this.closeOtherLunch();
                                        this.formData.id    = 304;
                                        this.formData.name  = 'Комплекс 4';
                                        this.formData.price = this.CALCS[3].price;
                                        this.lunch4 = true;
                                        break;
                        }
                                
                },
                closeOtherLunch()
                {
                        this.lunch1 = false;
                        this.lunch2 = false;
                        this.lunch3 = false;
                        this.lunch4 = false;
                },
                nextPage()
                {
                        this.e1 = 3;
                },
                backPage(page)
                {
                        this.e1 = page;  
                },
                getFormData(data)
                {
                        let saladData   = 0;
                        let soupData    = 0;
                        let garnirData  = 0;
                        let skewerData  = 0;
                        let drinkData   = 0;

                        if(data.salad.length)
                        {
                                saladData = this.salads.filter(item => item.name == data.salad);

                                saladData.forEach(element => {
                                        saladData = element.id;
                                });
                        }

                        if(data.soup.length)
                        {
                                soupData = this.soups.filter(item => item.name == data.soup);

                                soupData.forEach(element => {
                                        soupData = element.id;
                                });
                        }

                    
                        if(data.garnir.length)
                        {
                                garnirData = this.garnir.filter(item => item.name == data.garnir);

                                garnirData.forEach(element => {
                                        garnirData = element.id;
                                });
                        }


                        if(data.skewer.length)
                        {
                                skewerData = this.skewer.filter(item => item.name == data.skewer);

                                skewerData.forEach(element => {
                                        skewerData = element.id;
                                });
                        }

                        if(data.drink.length)
                        {
                                drinkData = this.drink.filter(item => item.name == data.drink);

                                drinkData.forEach(element => {
                                        drinkData = element.id;
                                });
                        }

                        let curId = saladData + soupData + garnirData + skewerData + drinkData;

                        let newData = 
                        data.soup+"/ "
                        +data.salad+"/ "
                        +data.garnir+"/ "
                        +data.skewer+"/ "
                        +data.drink;

                        this.formData.id                = curId;
                        this.formData.description       = newData;
                }
        },
        mounted(){
                this.GET_LUNCHCALCS_FROM_API().then((response)=>{
                        if(response.data)
                        {
                                // console.log(response.data);
                        }
                }),
                
                this.$set(this.formData, 'quantity', 1);
        
        },
}
</script>
